import * as React from "react"
import {
    // blue,
    // cyan,
    green,
    grey,
    // lime,
    orange,
    // pink,
    // purple,
    red,
    // teal,
} from "@mui/material/colors"
import PropTypes from "prop-types"
import { MemoryRouter, useLocation } from "react-router-dom"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
// import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import Container from "@mui/material/Container"
import Link from "@mui/material/Link"
// import MenuIcon from "@mui/icons-material/Menu"
import NotificationsIcon from "@mui/icons-material/Notifications"
import PersonIcon from "@mui/icons-material/Person"
import DrawerContents from "../components/DrawerContents"
import { GlobalDataContextProvider } from "../data/GlobalData"
import routes from "../routes.js"
import LoginDialog from "../components/LoginDialog"
import logo from "../assets/img/alpha-klimafulllogo.svg"
import { Divider } from "@mui/material"
function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://arfima.com/">
                Arfima Trading
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    )
}

function Logo() {
    return (
        <Box
            sx={{
                marginTop: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img src={logo} alt="logo" width="270" height="80" />{" "}

        </Box>
    )
}

// const drawerWidth = 175

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        width: `calc(100% )`,
    },
}))

export const themeOptions = {
    palette: {
        primary: {
            light: "#2a6685",
            main: "#2a6685",
        },
        secondary: {
            light: "#b7275b",
            main: "#b7275b",
        },
        success: {
            light: "#409043",
            main: "#409043",
        },
    },
    typography: {
        body: {
            fontSize: 14,
        },
        body2: {
            fontSize: 14,
        },
    },
    scores: {
        0: grey[400], // no data
        1: green[800], // low
        2: orange[800], // medium
        3: red[700], // high
        4: red[900], // red flag
        other: grey[400], // other
    },
    graphs: {
        0: "#0466C8",
        1: "#7D8597",
        2: "#001845",
        3: "#33415C",
        4: "#023E7D",
        5: "#979DAC",
    },
}

const appTheme = createTheme(themeOptions)

function Router(props) {
    const { children } = props
    return (
        // can also use BrowserRouter if preferred
        <MemoryRouter initialEntries={["/hazards"]} initialIndex={0}>
            {children}
        </MemoryRouter>
    )
}

// function ViewHeader() {
//     const location = useLocation()

//     const getCurrentPath = () => {
//         var route = routes.find((r) => r.path === location.pathname)
//         return route === undefined ? "Unknown" : route.longName
//     }
//     const currentPath = getCurrentPath()
//     return <Box sx={{ p: 0 }}>{currentPath}</Box>
// }

function ViewPanel(props) {
    const { component, path, ...other } = props

    const [rendered, setRendered] = React.useState(false)

    const location = useLocation()
    const visible = path === location.pathname
    if (visible && !rendered) setRendered(true)

    if (!rendered) return null

    let child
    if (!component) child = null
    else {
        child = component(visible)
    }
    return (
        <Box hidden={!visible} {...other}>
            {child}
        </Box>
    )
}

Router.propTypes = {
    children: PropTypes.node,
}

function AppContent() {
    // const [mobileOpen, setMobileOpen] = React.useState(false)
    const [loginOpen, setLoginOpen] = React.useState(false)
    const [open] = React.useState(true)

    // const handleDrawerToggle = () => {
    //     setMobileOpen(!mobileOpen)
    // }

    const handleLogin = () => {
        setLoginOpen(!loginOpen)
    }

    const handleLoginClose = () => {
        setLoginOpen(false)
    }

    return (
        <GlobalDataContextProvider>
            <ThemeProvider theme={appTheme}>
                <Router>
                    <Box sx={{ display: "flex" }}>
                        <CssBaseline />
                        <AppBar
                            position="absolute"
                            open={open}
                            elevation={0}
                            border={0}
                            sx={{
                                color: (theme) => theme.palette.grey[800],
                                mr: 0,
                                backgroundColor: (theme) =>
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                            }}
                        >
                            <Toolbar
                                sx={{
                                    width: "100%",
                                    left: 0,
                                    top: 0,
                                    position: "fixed",
                                }}
                            >
                                <DrawerContents routes={routes} />

                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        zIndex: 5,
                                        color: "white !important",
                                        display: "flex",
                                        alignItems: "right",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <IconButton
                                        color="inherit"
                                        onClick={handleLogin}
                                    >
                                        <Badge color="primary">
                                            <PersonIcon />
                                        </Badge>
                                    </IconButton>
                                    <IconButton color="inherit" sx={{ ml: 0 }}>
                                        <Badge
                                            badgeContent={0}
                                            color="secondary"
                                        >
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>

                        <Box
                            component="main"
                            sx={{
                                position: "relative",
                                backgroundColor: (theme) =>
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                                flexGrow: 1,
                                m: 1,
                                marginLeft: 10,
                                marginRight: 10,

                                p: 0,
                                minHeight: "100vh",
                            }}
                        >
                            <Toolbar />
                            <LoginDialog
                                open={loginOpen}
                                handleClose={handleLoginClose}
                                fullScreen={false}
                            ></LoginDialog>
                            <Container
                                maxWidth={false}
                                sx={{
                                    mt: 0,
                                    mb: 4,
                                    pl: 1,
                                    pr: 1,
                                }}
                                disableGutters
                            >
                                {/* Could have used <Routes> and <Route>, but we do not want the remounting */}
                                {routes.map((prop, key) => {
                                    return (
                                        <ViewPanel
                                            component={prop.component}
                                            path={prop.path}
                                            key={key}
                                        />
                                    )
                                })}
                                <Box sx={{ margin: 5 }}>
                                    <Divider orientation="horizontal" />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "right",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Logo />

                                    <Copyright sx={{ p: 5 }} />
                                </Box>
                            </Container>
                        </Box>
                    </Box>
                </Router>
            </ThemeProvider>
        </GlobalDataContextProvider>
    )
}

export default function App() {
    return <AppContent />
}
