import { React } from "react"
import { styled } from "@mui/material/styles"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import { ListItemLink } from "../components/ListItems"
import logo from "../assets/img/alpha-klimabaselogo.svg"
import Toolbar from "@mui/material/Toolbar"
import Box from "@mui/material/Box"

//https://unsplash.com/photos/rxlx9Yi0298?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink
// import bgImage from "../assets/img/noaa-rxlx9Yi0298-unsplash.jpg"

const StyledDivider = styled(Divider)`
    opacity: 0.3;
    z-index: 2;
    background-color: #fff;
`

const Logo = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 0.5,
                marginTop: 1,
                marginBottom: 1,
                borderRadius: "50%",
                backgroundColor: "#fff",
            }}
        >
            <img
                src={logo}
                alt="logo"
                width="35"
                height="35"
                style={{ marginLeft: "auto", marginRight: "auto" }}
            />
        </Box>
    )
}

const StyledDiv = styled("div")(() => ({
    elevation: 0,
    border: 0,
    zIndex: 0,
    position: "absolute",
    whiteSpace: "nowrap",
    top: "0",
    left: "0",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    background: "#265182",
    backgroundImage: `linear-gradient(to top, #183352, #265182)`,
    backgroundPosition: "left",
    backgroundSize: "150px 100%",
    // backgroundRepeat: "no-repeat",
    // backgroundImage: "url(" + bgImage + ")",
    // backgroundSize: "cover",
    // backgroundPosition: "center center",
    // "&:after": {
    //     elevation: 0,
    //     border: 0,
    //     zIndex: 0,
    //     position: "absolute",
    //     top: 0,
    //     left: 0,
    //     width: "100%",
    //     height: "100%",
    //     overflow: "hidden",
    //     whiteSpace: "nowrap",
    //     content: '""',
    //     display: "flex",
    //     background: "#000",
    //     opacity: "0.8",
    // },
}))

export default function DrawerContents(props) {
    const { routes } = props

    var links = (
        <List
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
                margin: 0,
                marginBottom: 1,
            }}
        >
            {routes
                .filter((prop) => prop.category === "primary")
                .map((prop, key) => {
                    return (
                        <ListItemLink
                            to={prop.path}
                            primary={prop.name}
                            icon={null}
                            key={key}
                        />
                    )
                })}
        </List>
    )

    const drawer = (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Toolbar
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: [1],
                }}
            >
                {/* <img src={logo} alt="logo" width="104" height="64" /> */}
                <Logo />
            </Toolbar>
            {links}
            <StyledDivider />
            {/* <List direction="row">
                {routes
                    .filter((prop) => prop.category === "secondary")
                    .map((prop, key) => {
                        return (
                            <ListItemLink
                                to={prop.path}
                                primary={prop.name}
                                icon={<prop.icon />}
                                key={key}
                            />
                        )
                    })}
            </List> */}
        </Box>
    )

    return (
        <Box
            sx={{
                overflow: "auto",
                "&::-webkit-scrollbar": {
                    display: "none",
                },
                display: "flex",
                flexDirection: "row !important",
            }}
        >
            <StyledDiv />
            {drawer}
        </Box>
    )
}
